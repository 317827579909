import './style.scss';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils';

export const TotalStaked: React.FC<{}> = () => {
  const { isLoggedIn } = useSelector<any, any>(({ profile }) => profile);
  const { allPoolInfo } = useSelector<any, any>(({ allPoolInfoSlice }) => allPoolInfoSlice);

  return (
    <div className='flex flex-col items-center md:items-start p-6 rounded-2xl border-[#45454F] border-[1px] bg-[#2B2B31]'>
      <img className='mb-5 w-12 h-12' src={'/assets/imgs/xl-icons/spores-bg-white.png'} alt='spores logo bg white' />
      <div className='title text-[#A2A2AF] mb-2'>Total staked across all pools</div>
      <div className='font-bold text-white text-xl leading-8'>
        {isLoggedIn ? `${formatNumber(Math.round(allPoolInfo?.amount ?? 0), '', 3)} SPO` : '-'}
      </div>
    </div>
  );
};
