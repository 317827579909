import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const PopupInfo = () => {
  return (
    <div className="text-left bg-[#262627] text-[#D5D5DC] px-8 py-10 flex flex-col gap-2 max-w-[400px]">
      <InfoOutlinedIcon fontSize="large"/>
      <div className="text-[#D3D3D4]">
        To join IDOs, you need to stake SPO to be eligible for VIP whitelist. We
        offer a flexible staking pool for you to stake/unstake anytime.
        <br />
        <br />
        <b>Be aware</b>: After unstaked, your VIP level will be recalculated
        based on the remaining staked amount.
      </div>
    </div>
  );
};

export default PopupInfo;
