import { store } from "reducers";
import { signOut } from "reducers/profileSlice";

export const configurableFetch = (
  input: RequestInfo | URL,
  init?: RequestInit
) =>
  fetch(input, init)
    .then((response: any) => {
      if (response.status === 401) {
        store.dispatch(
          signOut()
        );
        if (document.location.pathname !== "/profile") {
          document.location.reload();
        }
        else {
          document.location.href = '/';
        }
        return Promise.reject(response);
      }
      return response;
    });
