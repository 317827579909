import Web3 from 'web3';
import { erc20Abi, launchpad15Abi, flexibleStakingAbi, standardStaking, ferrumStaking } from './abis';

export const web3 = new Web3(window.ethereum);
export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const erc20Contract = (address) => new web3.eth.Contract(erc20Abi, address);
export const launchpad15Contract = (address) => new web3.eth.Contract(launchpad15Abi, address);
export const flexibleStakingContract = (address) => new web3.eth.Contract(flexibleStakingAbi, address);
export const standardStakingContract = (address) => new web3.eth.Contract(standardStaking, address);
export const ferrumStakingContract = (address) => new web3.eth.Contract(ferrumStaking, address);
