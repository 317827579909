import { CheckCircle } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { formatNumber, shorten } from 'utils';

const baseLinkBscScan = document.location.hostname.endsWith('.dev')
  ? 'https://testnet.bscscan.com/tx'
  : 'https://bscscan.com/tx';

type PopupDoneProps = {
  onClose: Function;
  amount: number | undefined;
  txHash: string;
  reward: number;
  decimals: number;
};

const UnStakeConfirmPopup: React.FC<PopupDoneProps> = ({ onClose, amount, txHash, reward, decimals }) => {
  const customizeReward = useMemo(
    () => formatNumber(new BigNumber(reward).div(10 ** decimals).toNumber(), '', 2),
    [decimals, reward],
  );

  return (
    <div className='box-border text-center px-8' style={{ maxWidth: '532px', backgroundColor: '#2B2B31' }}>
      <CheckCircle className='text-7xl' style={{ color: '#4CCD77' }} />
      <Typography variant='h3' className='my-4'>
        Success!
      </Typography>
      <Typography className='mb-4 text-[18px] text-[#D0D4DC]'>
        You have successfully unstaked your SPO tokens
      </Typography>
      <div className='w-full border-[1px] border-[#4F535A] p-4 text-[#D0D4DC] bg-[#23252A] rounded-lg'>
        <div className='flex justify-between w-full'>
          <div>Unstaked</div>
          <div className='font-bold text-white'>{amount} SPO</div>
        </div>
        {reward !== -1 && (
          <div className='flex justify-between w-full mt-1'>
            <div>Claimed Reward</div>
            <div className='font-bold text-[#51DE3A]'>{customizeReward} SPO</div>
          </div>
        )}
        <div className='flex justify-between w-full mt-1'>
          <div>Tx Hash</div>
          <a className='cursor-pointer hover:text-[#EECE7C]' href={`${baseLinkBscScan}/${txHash}`} target='_blank'>
            {shorten(txHash, 8, 8)}
          </a>
        </div>
      </div>
      <Button onClick={() => onClose()} className='w-[120px] mt-2 text-sm font-semibold rounded-full'>
        Done
      </Button>
    </div>
  );
};

export default UnStakeConfirmPopup;
