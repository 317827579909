import {
  Button,
  styled,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Tooltip,
} from '@mui/material';
import { EPoolType, PoolType } from 'helpers';
import './style.scss';
import { DateTime, Duration } from 'luxon';
import { isFinished, isStarted } from 'utils';

const HeadCell = styled(TableCell)`
  background: #36363f;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #45454f;
  padding: 16px 24px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
`;

const BodyCell = styled(TableCell)`
  background: #2b2b31;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #45454f;
  padding: 24px;
  font-size: 16px;
`;

const BodyRow = styled(TableRow)`
  :hover {
    td {
      background: #45454f;
    }
  }
`;

const CustomTable = styled(Table)`
  tr:last-child {
    td {
      border: none;
    }
  }
`;

const StatusTag = ({
  closeAt,
  openAt,
}: {
  closeAt: string;
  openAt: string;
}) => {
  const isFinish = isFinished(closeAt);
  const isStart = isStarted(openAt);
  const color = isStart ? (isFinish ? '#F53C5B' : '#51DE3A') : '#FFB938';
  const text = isStart ? (isFinish ? 'Closed' : 'Open') : 'Coming Soon';
  const remainTime = isFinish
    ? 0
    : Math.ceil(
        DateTime.fromISO(closeAt, { zone: 'utc' }).diff(DateTime.now(), [
          'days',
        ]).days,
      );
  const tillStartTime = isStart
    ? 0
    : Math.ceil(
      DateTime.fromISO(openAt, { zone: 'utc' }).diff(DateTime.now(), [
        'days',
      ]).days,
    );

  return (
    <div className="flex flex-col items-end">
      {!!closeAt && (
        <>
          <div className="text-white flex items-center gap-1">
            <span
              className="h-2 w-2 rounded-full inline-block"
              style={{ background: color }}
            ></span>{' '}
            {text}
          </div>
          {!isFinish && isStart && (
            <div className="text-[#8B8B9B] text-sm">{`${remainTime} day(s) left`}</div>
          )}
          {!isStart && (
            <div className="text-[#8B8B9B] text-sm">
              in {`${tillStartTime} day(s)`}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const TableList: React.FC<{
  poolsList: PoolType[];
  setChosenIndex: (index: number) => void;
  setIsOpenPopupFlexible: (open: boolean) => void;
  setIsOpenPopupStandard: (open: boolean) => void;
}> = ({
  poolsList,
  setChosenIndex,
  setIsOpenPopupFlexible,
  setIsOpenPopupStandard,
}) => {
  return (
    <CustomTable>
      <TableHead>
        <TableRow>
          <HeadCell>Pool name</HeadCell>
          <HeadCell className="text-center">Est. APR</HeadCell>
          <HeadCell>Min. locked day</HeadCell>
          <HeadCell>Staking Cap</HeadCell>
          <HeadCell>Status</HeadCell>
          <HeadCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {poolsList?.map((row: PoolType, index: number) => (
          <BodyRow key={index}>
            <BodyCell>
              <div className="w-44">
                <Tooltip placement="top" title={<div>{row.pool_name}</div>}>
                  <div
                    className="text-white font-bold text-ellipsis overflow-hidden break-word"
                    style={{
                      WebkitLineClamp: 2,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {row.pool_name}
                  </div>
                </Tooltip>
              </div>
            </BodyCell>
            <BodyCell>
              <div>
                {row.est_apr?.length === 1 ? (
                  <div className="font-bold text-[#51DE3A] text-center">{`${row.est_apr[0].value}%`}</div>
                ) : (
                  <div className="flex gap-2 justify-center">
                    {row.est_apr?.map((item, ind) => (
                      <div
                        className="text-center bg-[#36363F] rounded-lg py-1 px-4"
                        style={{ border: '1px solid #45454F' }}
                        key={ind}
                      >
                        <div className="font-bold text-[#51DE3A]">{`${item.value}%`}</div>
                        <div className="text-[#B9B9C3] text-sm">{`${item.time} days`}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </BodyCell>
            <BodyCell className="text-[#B9B9C3]">
              {row.est_apr?.length === 0
                ? '-'
                : `${
                    row.est_apr?.sort((a, b) => a.time - b.time)?.[0]?.time
                  } days`}
            </BodyCell>
            <BodyCell className="text-white">
              <div className="max-w-[120px]">
                {row.staking_cap === -1
                  ? 'Unlimited'
                  : `${row.staking_cap?.toLocaleString()} ${row.token_name}`}
              </div>
            </BodyCell>
            <BodyCell className="text-white">
              <StatusTag closeAt={row.close_at} openAt={row.start_at} />
            </BodyCell>
            <BodyCell className="text-white">
              {!isFinished(row.close_at) && isStarted(row.start_at) ? (
                <Button
                  className="w-full"
                  onClick={() => {
                    setChosenIndex(index);
                    if (row.type === EPoolType.Flexible) {
                      setIsOpenPopupFlexible(true);
                    } else {
                      setIsOpenPopupStandard(true);
                    }
                  }}
                >
                  <span className="block lg:hidden">Stake</span>
                  <span className="lg:block hidden">Stake now</span>
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  fullWidth
                  className="border-[#747486]"
                  onClick={() => {
                    setChosenIndex(index);
                    if (row.type === EPoolType.Flexible) {
                      setIsOpenPopupFlexible(true);
                    } else {
                      setIsOpenPopupStandard(true);
                    }
                  }}
                >
                  Check
                </Button>
              )}
            </BodyCell>
          </BodyRow>
        ))}
        <BodyRow>
          <BodyCell colSpan={5}>
            If you have staked $SPO before, please visit Spores Staking Pool V1.
          </BodyCell>
          <BodyCell className="text-white">
            <Button
              variant="outlined"
              fullWidth
              className="border-[#747486]"
              onClick={() => {
                window.open('https://staking-v1.spores.app/', '_blank');
              }}
            >
              Check
            </Button>
          </BodyCell>
        </BodyRow>
      </TableBody>
    </CustomTable>
  );
};
