import { createSlice } from '@reduxjs/toolkit';

const allPoolInfoSlice = createSlice({
  name: 'allPoolInfoSlice',
  initialState: {
    vipTiersData: [],
    allPoolInfo: {},
  },
  reducers: {
    updateAllPoolInfo: (state, { payload }) => {
      state = { ...state, allPoolInfo: payload };
      return state;
    },
    updateAllPoolInfoData: (state, { payload }) => {
      state = { ...state, ...payload };
      return state;
    },
  },
});

export const { updateAllPoolInfoData, updateAllPoolInfo } = allPoolInfoSlice.actions;

export default allPoolInfoSlice.reducer;
