import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { EPoolType, PoolType } from 'helpers';
import './style.scss';
import { DateTime, Duration } from 'luxon';
import {
  maturityReturn,
  earlyRewardReturn,
  isFinished,
  isStarted,
} from 'utils';

const StatusTag = ({
  closeAt,
  openAt,
}: {
  closeAt: string;
  openAt: string;
}) => {
  const isFinish = isFinished(closeAt);
  const isStart = isStarted(openAt);
  const color = isStart ? (isFinish ? '#F53C5B' : '#51DE3A') : '#FFB938';
  const text = isStart ? (isFinish ? 'Closed' : 'Open') : 'Coming Soon';
  const remainTime = isFinish
    ? 0
    : Math.ceil(
        DateTime.fromISO(closeAt, { zone: 'utc' }).diff(DateTime.now(), [
          'days',
        ]).days,
      );
  const tillStartTime = isStart
    ? 0
    : Math.ceil(
        DateTime.fromISO(openAt, { zone: 'utc' }).diff(DateTime.now(), ['days'])
          .days,
      );

  return (
    <div className="flex flex-col items-end">
      {!!closeAt && (
        <>
          <div className="text-white flex items-center gap-1">
            <span
              className="h-2 w-2 rounded-full inline-block"
              style={{ background: color }}
            ></span>{' '}
            {text}
          </div>
          {!isFinish && isStart && (
            <div className="text-[#8B8B9B] text-sm">{`${remainTime} day(s) left`}</div>
          )}
          {!isStart && (
            <div className="text-[#8B8B9B] text-sm">
              in {`${tillStartTime} day(s)`}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const TableListMobile: React.FC<{
  poolsList: PoolType[];
  setChosenIndex: (index: number) => void;
  setIsOpenPopupFlexible: (open: boolean) => void;
  setIsOpenPopupStandard: (open: boolean) => void;
}> = ({
  poolsList,
  setChosenIndex,
  setIsOpenPopupFlexible,
  setIsOpenPopupStandard,
}) => {
  return (
    <>
      {poolsList?.map((row: PoolType, index: number) => (
        <Accordion
          defaultExpanded={!isFinished(row.close_at)}
          disableGutters={true}
          key={index}
          className="text-white w-full"
        >
          <AccordionSummary className="bg-[#36363F]">
            <div className="flex justify-between items-center w-full">
              <div>
                <div
                  className="text-white font-bold text-ellipsis overflow-hidden break-word w-[200px]"
                  style={{
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {row.pool_name}
                </div>
              </div>
              <div>
                <StatusTag closeAt={row.close_at} openAt={row.start_at} />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="bg-[#2B2B31]">
            <div>
              {row.est_apr?.length === 1 ? (
                <div className="border-b-[1px] border-[#45454F] py-2 flex gap-2">
                  <div className="text-[#B9B9C3]">Est. APR:</div>
                  <div className="font-bold text-[#51DE3A]">{`${row.est_apr[0].value}%`}</div>
                </div>
              ) : (
                <div>
                  <div className="border-b-[1px] border-[#45454F] py-2 flex gap-2">
                    <div className="text-[#B9B9C3]">
                      {' '}
                      Maturity Return (
                      {`${maturityReturn(row.est_apr)?.time} days`})
                    </div>
                    <div className="font-bold text-[#51DE3A]">{`${
                      maturityReturn(row.est_apr)?.value
                    }%`}</div>
                  </div>
                  <div className="border-b-[1px] border-[#45454F] py-2 flex gap-2">
                    <div className="text-[#B9B9C3]">
                      Early Reward Return (
                      {`${earlyRewardReturn(row.est_apr)?.time} days`})
                    </div>
                    <div className="font-bold text-[#51DE3A]">{`${
                      earlyRewardReturn(row.est_apr)?.value
                    }%`}</div>
                  </div>
                </div>
              )}
            </div>

            <div className="border-b-[1px] border-[#45454F] py-2 flex gap-2">
              <div className="text-[#A2A2AF]">Staking Cap:</div>
              <div className="text-white font-bold">
                {row.staking_cap === -1
                  ? 'Unlimited'
                  : `${row.staking_cap?.toLocaleString()} ${row.token_name}`}
              </div>
            </div>

            <div className="py-2 flex gap-2">
              <div className="text-[#A2A2AF]">Minimum locked day:</div>
              <div className="text-white font-bold">
                {row.est_apr?.length === 0
                  ? '-'
                  : `${
                      row.est_apr?.sort((a, b) => a.time - b.time)?.[0]?.time
                    } days`}
              </div>
            </div>

            <div className="w-full mt-4">
              {!isFinished(row.close_at) && isStarted(row.start_at) ? (
                <Button
                  className="w-full"
                  onClick={() => {
                    if (row.type === EPoolType.Flexible) {
                      setChosenIndex(index);
                      setIsOpenPopupFlexible(true);
                    } else {
                      setChosenIndex(index);
                      setIsOpenPopupStandard(true);
                    }
                  }}
                >
                  <span className="block lg:hidden">Stake</span>
                  <span className="lg:block hidden">Stake now</span>
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  fullWidth
                  className="border-[#747486]"
                  onClick={() => {
                    if (row.type === EPoolType.Flexible) {
                      setChosenIndex(index);
                      setIsOpenPopupFlexible(true);
                    } else {
                      setChosenIndex(index);
                      setIsOpenPopupStandard(true);
                    }
                  }}
                >
                  Check
                </Button>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <Accordion disableGutters={true} className="text-white w-full">
        <AccordionSummary className="bg-[#36363F]">
          <div className="text-white font-bold">Spores Staking Pool V1</div>
        </AccordionSummary>
        <AccordionDetails className="bg-[#2B2B31]">
          <div className="flex flex-col gap-6">
            <div className="text-[#A2A2AF]">
              If you have staked $SPO before, please visit Spores Staking Pool
              V1.
            </div>
            <div className="text-white">
              <Button
                variant="outlined"
                fullWidth
                className="border-[#747486]"
                onClick={() => {
                  window.open('https://staking-v1.spores.app/', '_blank');
                }}
              >
                Check
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
