import { store } from "reducers";
import { signIn } from "reducers/profileSlice";
import { connectAddress } from 'utils/wallet';
import { web3 } from 'contracts/contract';
import { connect, login } from "services/account";

export const connectWallet = async (callback) => {
  const addr = await connectAddress();

  const { isLoggedIn } = store.getState().profile;
  if (isLoggedIn) {
    if (typeof callback === "function") callback();
  } else {
    try {
      let { nonce } = await connect(addr);
      nonce = nonce + "";
      const message = `Welcome to Spores Launchpad!\n\nClick \"Sign\" to sign in. No password needed!\n\nWallet address:\n${addr}\n\nNonce:\n${nonce}`;
      const rawSig = await web3.eth.personal.sign(message, addr);
      const splitAt = rawSig.length - 2;

      // for Ethereum, last bytes of the signature must be 1b or 1c
      // Metamask did this automatically but for hardware wallet like Trezor or Ledger,
      // this must be done manually
      let v = rawSig.slice(-2);
      if (v === "00") {
        v = "1b";
      } else if (v === "01") {
        v = "1c";
      }
      const signature = rawSig.substring(0, splitAt) + v;

      const { token } = await login({
        addr,
        nonce,
        signature,
      });
      store.dispatch(signIn({ address: addr, token }));

      if (typeof callback === "function") callback();
    } catch {}
  }
};
