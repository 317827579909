import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrivateLayout } from "layouts";

import { Home } from "views/home";
import { Loading } from "components";

import "./App.scss";

export const App = () => {
  return (
    <Router>
      <Suspense fallback={<Loading text="Loading, please wait..." />}>
        <Routes>
          <Route path="/" element={<PrivateLayout />}>
            <Route index element={<Home />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};
