import { Pagination, styled, Dialog } from '@mui/material';
import { PoolType } from 'helpers';
import { useEffect, useState } from 'react';
import { getStakingApi } from 'swagger';
import { PopupPool } from '../popup-pool';
import { PopupStandardPool } from '../popup-standard-pool';
import './style.scss';
import { TableList } from './table';
import { TableListMobile } from './table-mobile';
import { DateTime } from 'luxon';
import { isFinished, isStarted } from 'utils';

const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 100%;
    @media (max-width: 500px) {
      margin: 0px;
    }
  }
`;

const CustomPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    background: #2b2b31;
    border: 1px solid #45454f !important;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
  }
  .MuiPaginationItem-ellipsis {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Mui-selected {
    background: #2b2b31 !important;
    border: 1px solid #eece7c !important;
    border-radius: 4px !important;
    color: #eece7c !important;
  }
`;

export const PoolsTable: React.FC<{}> = () => {
  const [chosenIndex, setChosenIndex] = useState(0);
  const [isOpenPopupFlexible, setIsOpenPopupFlexible] = useState(false);
  const [isOpenPopupStandard, setIsOpenPopupStandard] = useState(false);
  const [poolsList, setPoolsList] = useState<PoolType[]>([]);

  useEffect(() => {
    getStakingApi()
      .apiStakingPoolsGet()
      .then((res) => res.json())
      .then(({ data }: { data: PoolType[] }) => {
        const openPools = data.filter(
          (pool) => isStarted(pool.start_at) && !isFinished(pool.close_at),
        );
        const comingPools = data.filter((pool) => !isStarted(pool.start_at));
        const closedPools = data.filter((pool) => isFinished(pool.close_at));

        setPoolsList([...openPools, ...comingPools, ...closedPools]);
      });
  }, []);
  return (
    <div className="flex flex-col gap-8 items-center">
      <div
        className="rounded-2xl overflow-hidden w-full"
        style={{ border: '1px solid #45454f' }}
      >
        <div className="md:block hidden">
          <TableList
            poolsList={poolsList}
            setChosenIndex={(index: number) => setChosenIndex(index)}
            setIsOpenPopupFlexible={(open: boolean) =>
              setIsOpenPopupFlexible(open)
            }
            setIsOpenPopupStandard={(open: boolean) =>
              setIsOpenPopupStandard(open)
            }
          />
        </div>
        <div className="block md:hidden">
          <TableListMobile
            poolsList={poolsList}
            setChosenIndex={(index: number) => setChosenIndex(index)}
            setIsOpenPopupFlexible={(open: boolean) =>
              setIsOpenPopupFlexible(open)
            }
            setIsOpenPopupStandard={(open: boolean) =>
              setIsOpenPopupStandard(open)
            }
          />
        </div>
      </div>
      <CustomPagination count={1} />
      {isOpenPopupFlexible && (
        <CustomDialog maxWidth="md" open={isOpenPopupFlexible}>
          <PopupPool
            handleClose={() => setIsOpenPopupFlexible(false)}
            poolData={poolsList[chosenIndex]}
          />
        </CustomDialog>
      )}
      {isOpenPopupStandard && (
        <CustomDialog maxWidth="md" open={isOpenPopupStandard}>
          <PopupStandardPool
            handleClose={() => setIsOpenPopupStandard(false)}
            poolData={poolsList[chosenIndex]}
          />
        </CustomDialog>
      )}
    </div>
  );
};
