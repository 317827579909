import './style.scss';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import { getUserData } from 'reducers/allPoolInfoAction';

const getVipLevel = (vipTiersData: any[], stakedAmount: number) => {
  const convertedVipTiersData = vipTiersData.map((vipTierData) => {
    return { ...vipTierData, amount: Number(vipTierData.amount) };
  });
  convertedVipTiersData.sort((a, b) => a.amount - b.amount);

  let currentVip = null;
  let nextVip = null;
  for (const convertedVipTierData of convertedVipTiersData) {
    if (stakedAmount >= convertedVipTierData.amount) {
      currentVip = convertedVipTierData;
    } else {
      nextVip = convertedVipTierData;
      break;
    }
  }

  return [currentVip, nextVip];
};

export const MyVipLevel: React.FC<{}> = () => {
  const { allPoolInfo, vipTiersData } = useSelector<any, any>(({ allPoolInfoSlice }) => allPoolInfoSlice);
  const [currentVip, setCurrentVip] = useState<any>();

  useEffect(() => {
    const [resCurrentVip] = getVipLevel(vipTiersData, allPoolInfo?.amount ?? 0);
    setCurrentVip(resCurrentVip);
  }, [allPoolInfo?.amount, vipTiersData]);

  return (
    <div className='flex flex-col items-center md:items-start p-6 rounded-2xl border-[#45454F] border-[1px] bg-[#2B2B31]'>
      <img
        className='mb-2 w-[54px] h-[62px]'
        src={currentVip ? currentVip.imgXl : '/assets/imgs/tier-system-xl/default.png'}
        alt='vip level'
      />
      <div className='flex gap-0.5 items-center mb-2'>
        <div className='title text-[#A2A2AF]'>My VIP Level</div>
        <Tooltip placement='top' title={<div>Reload</div>}>
          <IconButton className='hover:bg-[#36363F] rounded-lg px-2 py-1' onClick={getUserData}>
            <img src='/assets/imgs/xl-icons/icon-reload.png' className='w-3 h-3' />
          </IconButton>
        </Tooltip>
      </div>
      <div className='font-bold text-white text-xl leading-8'>{currentVip ? currentVip.name : '-'}</div>
    </div>
  );
};
