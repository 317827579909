import { compose } from "ramda";

import { App } from "./App";

import { withQuery } from "./withQuery";
import { withTheme } from "./withTheme";
import { withRedux } from "./withRedux";
import { withSnackbar } from "./withSnackbar";

export default compose(
  withSnackbar,
  withRedux,
  withTheme,
  withQuery,
)(App);