import { Button, Dialog } from '@mui/material';
import { DateTime } from 'luxon';
import {
  unstake,
  pendingReward,
  userInfo,
  getDecimals,
  getUserInfo,
  standardPoolUnstake,
  standardPoolPendingReward,
  standardPoolUserStakeAmount,
  getCurrentBalance,
  ferrumPoolUserStakeAmount,
  ferrumPoolUnstake,
} from 'contracts/helpers';
import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { formatNumber, isFinished, isStarted } from 'utils';
import { getStakingApi } from 'swagger';
import usePopup from 'hooks/Popup';
import UnStakeConfirmPopup from './unStakeConfirmPopup';
import { connectWallet } from 'reducers/profileAction';
import PopupInfo from './popupInfo';
import UnStakeConfirmation from './unStakeConfirmation';
import BigNumber from 'bignumber.js';
import LoadingButton from '@mui/lab/LoadingButton';
import ProcessingPopup from './processingPopup';
import { propOr } from 'ramda';
import { PoolsInfo } from './pools-info';
import { EPoolType, PoolType } from 'helpers';
import { getUserData } from 'reducers/allPoolInfoAction';

export const UnstakePanel: React.FC<{
  classNames: any;
  poolData?: PoolType;
  handleSuccess?: () => void;
}> = ({ classNames, poolData, handleSuccess }) => {
  const poolsInfoRef = useRef<any>();
  const [balance, setBalance] = useState<number>(0);
  const [reward, setReward] = useState<number>(0);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [isFill, setIsFill] = useState(true);
  const [txHash, setTxHash] = useState<string>('');
  const [stakedAmount, setStakedAmount] = useState<number>(0);
  const [decimals, setDecimals] = useState<number>(0);
  const [isAvailableToUnStake, setIsAvailableToUnStake] = useState<boolean>();
  const [unStakeDate, setUnStakeDate] = useState<any>();

  const { isLoggedIn, address: walletAddress } = useSelector<any, any>(
    ({ profile }) => profile,
  );
  const isStandardPool = poolData?.type === EPoolType.Standard;
  const isFlexiblePool = poolData?.type === EPoolType.Flexible;
  const [loading, setLoading] = useState<boolean>(false);

  const getTimeToUnstake = useCallback(async () => {
    if (!poolData) return;
    try {
      let timeToUnStake;
      if (isFlexiblePool) {
        timeToUnStake = await getUserInfo(
          poolData?.pool_index?.toString(),
          walletAddress,
          poolData?.contract_address,
        );
      } else {
        const unstakeTime = DateTime.fromISO(poolData?.close_at)
          .plus({ days: poolData?.est_apr?.[0]?.time })
          .toSeconds();
        timeToUnStake = { canUnstakeAt: unstakeTime?.toString() };
      }
      setUnStakeDate(
        DateTime.fromSeconds(Number(timeToUnStake.canUnstakeAt)).toFormat(
          'MMMM dd yyyy',
        ),
      );

      const currentTime = DateTime.now().toUnixInteger();
      const canUnstakeAt = propOr<number, unknown, number>(
        0,
        'canUnstakeAt',
        timeToUnStake,
      );
      const isAvailableToUnStake = canUnstakeAt < currentTime;
      if (!isAvailableToUnStake) {
        setIsAvailableToUnStake(false);
      } else {
        setIsAvailableToUnStake(true);
      }
    } catch (e) {
      console.log(e);
    }
  }, [poolData, isFlexiblePool, walletAddress]);

  const getBalance = useCallback(async () => {
    if (!isLoggedIn || !poolData) return;
    try {
      const resBalance = await getCurrentBalance(
        poolData?.token_address,
        walletAddress,
      );
      setBalance(resBalance);
      const resDecimal = await getDecimals(poolData?.token_address);
      setDecimals(resDecimal);
      return { balance: resBalance, decimal: resDecimal };
    } catch (e) {
      console.log(e);
      return {};
    }
  }, [isLoggedIn, poolData, walletAddress]);

  const getPendingReward = useCallback(async () => {
    if (!isLoggedIn || !poolData) return;
    try {
      let res;
      if (isStandardPool) {
        res = await standardPoolPendingReward(
          poolData?.contract_address,
          walletAddress,
        );
      } else if (isFlexiblePool) {
        res = await pendingReward(
          poolData?.contract_address,
          poolData?.pool_index?.toString(),
          walletAddress,
        );
      } else {
        res = -1;
      }
      setReward(res);
    } catch (e) {
      console.log(e);
    }
  }, [isFlexiblePool, isLoggedIn, isStandardPool, poolData, walletAddress]);

  const getStakeAmount = useCallback(
    async (paramDecimal: number) => {
      if (!isLoggedIn || !poolData) return;
      try {
        let res;
        if (isStandardPool) {
          res = await standardPoolUserStakeAmount(
            poolData?.contract_address,
            walletAddress,
          );
        } else if (isFlexiblePool) {
          const rawRes = await userInfo(
            poolData?.contract_address,
            poolData?.pool_index?.toString(),
            walletAddress,
          );
          res = rawRes?.amount;
        } else {
          res = await ferrumPoolUserStakeAmount(
            poolData?.contract_address,
            walletAddress,
          );
        }
        res = new BigNumber(res).div(10 ** paramDecimal).toNumber();
        if (isStandardPool) {
          setAmount(res);
        }
        setStakedAmount(res);
      } catch (e) {
        console.log(e);
      }
    },
    [isFlexiblePool, isLoggedIn, isStandardPool, poolData, walletAddress],
  );

  const getInfos = useCallback(async () => {
    await getTimeToUnstake();
    const res = await getBalance();
    await getPendingReward();
    await getStakeAmount(res?.decimal);
  }, [getBalance, getPendingReward, getStakeAmount, getTimeToUnstake]);

  useEffect(() => {
    getInfos();
  }, [getInfos]);

  const [isOpenDone, onOpenDone, onCloseDone] = usePopup(false);
  const [isOpenConfirm, onOpenConfirm, onCloseConfirm] = usePopup(false);

  const handleUnstake = () => {
    if (!amount) {
      setIsFill(false);
    } else {
      setIsFill(true);
      onOpenConfirm();
    }
  };
  const onUnStake = async () => {
    setLoading(true);
    try {
      if (!isLoggedIn || !poolData || !amount || amount > stakedAmount) return;

      // await allowanceAndApprove(
      //   poolInfo.lpTokenAddress,
      //   poolInfo.contractAddress,
      //   walletAddress,
      //   amount,
      // );
      let tx_hash = '';
      if (isStandardPool) {
        tx_hash = await standardPoolUnstake(
          poolData?.contract_address,
          walletAddress,
        );
      } else if (isFlexiblePool) {
        tx_hash = await unstake(
          poolData?.token_address,
          poolData?.contract_address,
          walletAddress,
          poolData?.pool_index?.toString(),
          amount,
        );
      } else {
        tx_hash = await ferrumPoolUnstake(
          poolData?.contract_address,
          walletAddress,
          amount,
          decimals,
        );
      }

      setTxHash(tx_hash);

      await getStakingApi().apiStakingPidUnstakePost(
        {
          pid: poolData?.pool_index?.toString(),
        },
        {
          amount,
          tx_hash,
        },
      );
      onOpenDone();
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  const isInvalid = useMemo(
    () => !!(amount && amount > stakedAmount),
    [amount, stakedAmount],
  );
  const onClosePopDone = () => {
    if (handleSuccess) {
      handleSuccess();
    }
    getInfos();
    setAmount(0);
    getUserData();
    poolsInfoRef.current?.reload?.();
    onCloseDone();
  };
  const [isOpenInfo, , onCloseInfo] = usePopup(false);

  return (
    <div>
      <div className="text-[#A2A2AF]">
        <div className="text-sm font-bold mb-2">Enter amount</div>
        <div
          className={`flex justify-between gap-2 pl-3 p-1 text-white ${
            classNames.numberInput
          } ${isInvalid || !isFill ? classNames.numberInputHasError : ''}`}
        >
          <NumberFormat
            disabled={!isLoggedIn || isStandardPool || !isAvailableToUnStake}
            value={amount}
            onValueChange={({ floatValue: value }) => {
              if (value !== undefined) {
                setIsFill(true);
              }
              setAmount(value);
            }}
            maxLength={9}
            allowNegative={false}
            decimalScale={0}
            className="text-start bg-transparent text-lg font-bold numberinput--placeholder w-full"
            style={{ maxWidth: '80%' }}
          />
          <div className="">
            <button
              className={classNames.buttonMax}
              onClick={() => setAmount(Math.floor(stakedAmount))}
            >
              Max
            </button>
          </div>
        </div>
        {isInvalid && (
          <span className="text-[#F53C5B]">
            Your maximum amount is {formatNumber(stakedAmount)}{' '}
            {poolData?.token_name}
          </span>
        )}

        {isAvailableToUnStake === false && (
          <span className="text-[#F53C5B]">
            You can't unstake until {unStakeDate}{' '}
          </span>
        )}

        {!isFill && <span className="text-[#F53C5B]">Please enter amount</span>}

        <div className="flex justify-between items-center mb-4">
          <div>
            Balance:{' '}
            <span className="text-white font-bold">
              {isLoggedIn
                ? `${formatNumber(balance, '', 0)} ${poolData?.token_name}`
                : '-'}
            </span>
          </div>
          <a
            href="https://pancakeswap.finance/swap?outputCurrency=0x8357c604c5533fa0053beaaa1494da552cea38f7"
            target="_blank"
            className="text-[#EECE7C] font-bold lg:font-normal leading-[40px]"
          >
            Get SPO
          </a>
        </div>
        <PoolsInfo ref={poolsInfoRef} poolData={poolData} />
        <div className="">
          {!isLoggedIn ? (
            <Button fullWidth onClick={connectWallet}>
              Connect wallet
            </Button>
          ) : (
            <LoadingButton
              fullWidth
              variant="contained"
              className={`rounded-lg ${
                isInvalid ? classNames.buttonDisabled : ''
              } py-3 leading-5`}
              onClick={handleUnstake}
              disabled={
                isInvalid || loading || !isAvailableToUnStake || amount === 0
              }
            >
              Unstake
            </LoadingButton>
          )}
        </div>
      </div>
      {isOpenDone && txHash && (
        <Dialog open={true} onClose={onClosePopDone} maxWidth="xs">
          <UnStakeConfirmPopup
            onClose={onClosePopDone}
            amount={amount}
            txHash={txHash}
            reward={reward}
            decimals={decimals}
          />
        </Dialog>
      )}
      {isOpenInfo && (
        <Dialog open={isOpenInfo} onClose={onCloseInfo} maxWidth="lg">
          <PopupInfo />
        </Dialog>
      )}
      {isOpenConfirm && (
        <Dialog open={isOpenConfirm} onClose={onCloseConfirm} maxWidth="lg">
          <UnStakeConfirmation onClose={onCloseConfirm} onUnStake={onUnStake} />
        </Dialog>
      )}
      {loading && (
        <Dialog open={true}>
          <ProcessingPopup />
        </Dialog>
      )}
    </div>
  );
};
