import { ACTIVE_POOL } from 'env';
import { store } from 'reducers';
import { getStakingApi } from 'swagger';
import { updateAllPoolInfo, updateAllPoolInfoData } from './allPoolInfoSlice';

export const getData = async () => {
  try {
    const { data: dataVipTier } = await getStakingApi()
      .apiStakingPidVipStakingGet({
        pid: ACTIVE_POOL,
      })
      .then((res) => res.json());
    store.dispatch(
      updateAllPoolInfoData({
        vipTiersData: dataVipTier?.filter((item) => item.name !== 'VIP 0'),
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

export const getUserData = async () => {
  const { isLoggedIn } = store.getState().profile;
  try {
    if (!isLoggedIn) {
      store.dispatch(updateAllPoolInfo({}));
    } else {
      const { data: dataAllPool } = await getStakingApi()
        .apiStakingUserStatusGet()
        .then((res) => res.json());
      store.dispatch(updateAllPoolInfo(dataAllPool));
    }
  } catch (e) {
    console.log(e);
  }
};
