import { Box, Typography, Tabs, Tab, styled } from '@mui/material';
import { PoolType } from 'helpers';
import React from 'react';
import { isFinished } from 'utils';
import { StakePanel, UnstakePanel } from './components';

import classNames from './stake-unstake.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="">
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomTabs = styled(Tabs)`
  padding: 4px;
  margin-bottom: 28px;
  background: #2b2b31;
  border: 1px solid #45454f;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTab-root {
    width: 50%;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #737382 !important;
    text-transform: none !important;
    padding: 4px 12px !important;
    min-height: auto !important;
    line-height: 28px;
  }
  .MuiTab-root.Mui-selected {
    color: #fff !important;
    background: #36363f;
    box-shadow: 0px 0px 1px rgba(13, 13, 13, 0.9), 0px 1px 3px #0d0d0d;
    border-radius: 8px;
  }
  .MuiTabs-scroller {
    overflow: visible !important;
  }
`;

const CustomTabClosed = styled(Tabs)`
width: 50%;
  padding: 4px;
  margin-bottom: 28px;
  background: #2b2b31;
  border: 1px solid #45454f;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTab-root {
    width: 100%;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #737382 !important;
    text-transform: none !important;
    padding: 4px 12px !important;
    min-height: auto !important;
    line-height: 28px;
  }
  .MuiTab-root.Mui-selected {
    color: #fff !important;
    background: #36363f;
    box-shadow: 0px 0px 1px rgba(13, 13, 13, 0.9), 0px 1px 3px #0d0d0d;
    border-radius: 8px;
  }
  .MuiTabs-scroller {
    overflow: visible !important;
  }
`;

export const StakeUnstacke: React.FC<{ poolData?: PoolType }> = ({
  poolData,
}) => {
  const [value, setValue] = React.useState(0);
  const isClosed = isFinished(poolData?.close_at ?? '');
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      className="bg-[#2B2B31] rounded-lg flex flex-col justify-between h-full"
      sx={{ width: '100%' }}
    >
      {isClosed ? (
        <div>
          <CustomTabClosed value={value} onChange={handleChange}>
            <Tab label="Unstake" {...a11yProps(0)} />
          </CustomTabClosed>
          <UnstakePanel classNames={classNames} poolData={poolData} />
        </div>
      ) : (
        <div>
          <CustomTabs value={value} onChange={handleChange}>
            <Tab disableRipple label="Stake" {...a11yProps(0)} />
            <Tab disableRipple label="Unstake" {...a11yProps(1)} />
          </CustomTabs>
          <TabPanel value={value} index={0}>
            <StakePanel classNames={classNames} poolData={poolData} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UnstakePanel classNames={classNames} poolData={poolData} />
          </TabPanel>
        </div>
      )}
    </Box>
  );
};
