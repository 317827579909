import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { PoolType } from 'helpers';
import { StakeUnstacke } from '../stake-unstake';
import './style.scss';

const InfoLine = ({ title, value, color }: { title: string; value: string; color?: string }) => (
  <div className='flex justify-between'>
    <div className='text-[#A2A2AF]'>{title}</div>
    <div className='font-bold' style={{ color: color ?? 'inherit' }}>
      {value}
    </div>
  </div>
);

export const PopupPool = ({ handleClose, poolData }: { handleClose: () => void; poolData: PoolType }) => {
  return (
    <div className='text-white rounded-2xl bg-[#2B2B31] w-full'>
      <div
        className='bg-[#36363F] px-8 py-4 flex justify-between items-center'
        style={{ borderBottom: '1px solid #45454F' }}
      >
        <div className='font-bold text-xl'>{poolData.pool_name}</div>
        <IconButton onClick={handleClose}>
          <Close className='text-[#B9B9C3]' />
        </IconButton>
      </div>
      <div className='flex flex-col md:flex-row'>
        <div className='px-8 py-6 text-sm flex-1' style={{ borderRight: '1px solid #45454F' }}>
          <StakeUnstacke poolData={poolData} />
        </div>
        <div className='px-8 py-6 text-sm md:w-[358px]'>
          <div className='font-bold mb-4'>Pool Information</div>
          <div className='flex flex-col gap-4'>
            <InfoLine title='APR' value={`${poolData.est_apr?.[0]?.value ?? '- '}%`} color='#51DE3A' />
            <InfoLine title='Reward Calculation' value='Daily basis' />
            <InfoLine
              title='Staking Cap'
              value={poolData.staking_cap === -1 ? 'Unlimited' : `${poolData.staking_cap} SPO`}
            />
            <InfoLine title='Minimum lock' value={`${poolData.est_apr?.[0]?.time ?? '-'} days`} />
          </div>
        </div>
      </div>
    </div>
  );
};
